
.reviewinfo{
  position:sticky;
  top:5vh;
  left:5vw;
  padding: 2%;
  color:#fff;
  z-index: 1;
  background-color: rgba(19,43,71,.9);
  inline-size: 30vw;
}

.reviewheader{
  font-size:2.5em;
  margin-bottom: 2vh;
}

.reviewtext{
  font-size:.9em;
}

.testimonial-bg{
    border: 3px solid #FFFFFF;
    padding: 5% 10% 5%;
    text-align: center;
    position: relative;
}
.testimonial-bg:before{
    font-family: 'Secular One', sans-serif;
    content: '"';
    width: 6vw;
    height: 10vh;
    line-height: 12vh;
    text-align: center;
    font-size: 6.5em;
    color: #0EFEC1;
    position: absolute;
    top: -3.6vh;
    left: 0;
}
.testimonial{
    padding: 0 15px;
}
.testimonial .description{
    font-size: 1em;
    font-weight:400;
    font-style: italic;
    color: #FFFFFF;
    padding-bottom: 5vh;
    position: relative;
    border-bottom: 1px solid #ddd;
}

.testimonial .description small{
    color: #0EFEC1;
}

.testimonial .pic{
    width: 5vw;
    height: auto;
    border-radius: 50%;
    margin: 10px auto;
    overflow: hidden;
}
.testimonial .pic img{
    width: 100%;
    height: auto;
}
.testimonial .title{
    display: inline-block;
    font-size: 1.5em;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: capitalize;
    margin: 0;
}
.testimonial .post{
    display: inline-block;
    font-size: 1em;
    color: #FFFFFF;
}

.owl-theme .owl-controls .owl-buttons div{
    width: 3vw;
    height: 3vh;
    background-color: rgba(0,0,0,0);
    opacity: 1;
}
.owl-prev:before,
.owl-next:before{
    content: "<";
    font-size: 2em;
    font-weight: 700;
    color: #0EFEC1;
}
.owl-next:before{
    content:">";
}

@media (max-width: 991px) {
  .reviewinfo{
    width: 80vw;
  }
  .review-section{
    padding-bottom: 15vh;
  }
  .testimonial .pic{
    width: 20vw;
  }
}

@media (max-width: 600px) {
  .reviewinfo{
    line-height: normal;
    background: none;
  }

  .testimonial-bg{
    top: -7vh;
  }

}
